import { Helmet } from "react-helmet";
import LinkButton from "./LinkButton";

export default function App() {
  return (
    <div className="fixed h-full w-full bg-gray-700 flex items-center justify-center gap-6">
      <Helmet>
        <title>ponchotang</title>
        <meta name="description" content="Random shit made by Ponchotang" />
      </Helmet>

      <div className="absolute top-10 w-full text-center">
        <h1 className="text-white text-3xl font-bold">hi im ponchotang, heres some dumb shit i made</h1>
      </div>

      <LinkButton to="/noise">noise generator</LinkButton>
      <LinkButton to="/about">about me</LinkButton>
    </div>
  )
}
